/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable import/order */

import React, { useState, useEffect, } from 'react'
import type { GetServerSidePropsContext } from "next";

// import Head from "next/head";
// import Link from "next/link";
import { useRouter } from 'next/router'
import Image from 'next/image'
import Loading from "@aimor/components/Loading"

// import { styled } from '@mui/material/styles'
import { api } from "@aimor/utils/api";
import logo from "/public/images/logo.svg"
import Footer from "@aimor/components/Footer"
import { createClient } from "@aimor/utils/supabase/component"
import { createClient as createServerClient } from "@aimor/utils/supabase/server-props"
import openEye from "../../../public/images/password - visible.svg"
import shutEye from "../../../public/images/password - invisible.svg"

export default function Login() {

    const router = useRouter();
    const supabase = createClient()

    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('');


    const [isClicked, setIsClicked] = useState(false);
    // const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('Please enter your email address');
    const createWaitlistEmailMutation = api.waitlistEmail.addEmailToWaitlist.useMutation();
    const [showButton, setShowButton] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showValidityWarning, setShowValidityWarning] = useState(false);
    const [isSent, setIsLoading] = useState(false)


    const [vPWLength, setVPWLength] = useState(true);
    const [vEmail, setVEmail] = useState(true);
    const [vUser, setVUser]= useState(true);


    //TODO: reset validation values in state after second reentry!

    // Function to validate the email format
    const validateEmail = (email: string) => {
        const regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    };

    function checkInputValidity() {
        let valid = true;
        if (!validateEmail(email)) {
             console.error('ERROR: Invalid Email');
              valid = false;
            setVEmail(false) }
        if (password.length<8){
            console.error('ERROR: PW must be 8 chars min');
            valid = false;
            setVPWLength(false)
        }
        return valid
    }

    async function handleSubmitLogIn() {
        setIsLoading(true)
        setVEmail(true);
        setVPWLength(true);


         console.log('LOGGING IN WITH, ', email, password)

        if (!checkInputValidity()) {
            console.log('failed for listed reason')
            setIsLoading(false)
            return
        } else {
            const {data, error } = await supabase.auth.signInWithPassword({ email, password })
            if (error) {
                console.error(error, "user login failed")
                setVUser(false)
                setIsLoading(false)
                return
            }
            router.push('/userDashboard')
        }
    }



    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        switch (name) {
            case 'email':
                setEmail(value);
                break;
            case 'password':
                setPassword(value);
                break;
            default:
                console.error('Invalid field name');
        }

    };



    return (
        <div className=' md:bg-gradient-to-b  md:from-[#5B45FF] md:to-white flex flex-col flex-grow items-center justify-center md:pt-16 overflow-auto '>

            <div id="homepage-outer" className= {`flex flex-col flex-grow md:w-5/6   md:rounded-3xl md:max-w-4xl   bg-white   pb-6  ${isSent ? "h-[100vh] w-full" : "" }`} style={{ boxShadow: '0 4px 50px rgba(50, 22, 255, 0.4)' }}>

                {isSent && <Loading></Loading>}
                {!isSent &&
                    <div>
                        <button
                            className="text-left font-gabarito pl-8 pt-6 text-AIMOR_VIOLET text-md"
                            onClick={() => { router.back() }}
                        >
                            {"< Go back"}
                        </button>
                        <div id="homepage-contents" className="flex flex-col items-center gap-2 ">
                            {/*eslint-disable-next-line @typescript-eslint/no-unsafe-assignment*/}
                            <Image src={logo} alt="you should be seeing a logo here" className="h-28" />



                            {/* EMAIL PASSWORD AND PW RECOVERY */}
                            <div className='font-gabarito text-AIMOR_VIOLET w-[80%] md:w-[50%] py-4'>

                                <p>Email</p>
                                <input
                                    type="text"
                                    placeholder="Enter your email address here..."
                                    id="filled-basic-dui"
                                    value={email}
                                    name="email"
                                    className={`input w-full h-[40px] rounded-lg input-bordered  focus:border-AIMOR_VIOLET focus:outline-none font-gabarito  text-left pl-4  border-2  ${(vEmail ? "" : "border-4 border-red-500")} `}
                                    onChange={handleChange}
                                    disabled={isClicked}
                                />
                                {!vEmail && <p className='text-red-500'>{`Please enter a valid email address`}</p>}

                                <div className="p-1"></div>



                                                        

                            <p>Password</p>
                                <div className={`flex flex-row font-gabarito focus:border-AIMOR_VIOLET focus:outline-none text-left pl-4 border-2 rounded-lg justify-between  ${(vPWLength? "" : "border-4 border-red-500")}`}>
                                    <input
                                        type={showPassword ? "text" : "password"} // Toggle between text and password based on showPassword state
                                        placeholder="Enter your password here..."
                                        id="filled-basic-dui"
                                        value={password}
                                        name="password"
                                        className="input w-full h-[40px] rounded-lg font-gabarito focus:border-AIMOR_VIOLET focus:outline-none text-left"
                                        onChange={handleChange}
                                        disabled={isClicked} // Make sure you define isClicked somewhere
                                    />
                                    <button
                                        className="btn pr-3"
                                        onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state
                                    >
                                        {showPassword ? <Image src={openEye} alt="Hide password" /> : <Image src={shutEye} alt="Show password" />}
                                    </button>
                                </div>
                            { !vPWLength && <p className='text-red-500'>{`Password must be 8+ characters`}</p> }

                            <div className="p-1"></div>

                            {!vUser && <p className='text-red-500'>{`Failed to log in; username and/or password incorrect`}</p>}


                            <button className = "underline font-gabarito text-AIMOR_VIOLET pt-4" onClick = {()=>router.push('/forgottenPassword')}>I forgot my password </button>

                            </div>
                            {/* <div className="p-1"> </div> */}


                            <input
                                type="button"
                                id="submit-button"
                                value="Log in"
                                className={`input w-[220px] h-[45px] border-none rounded-lg font-gabarito text-white bg-AIMOR_VIOLET text-center custom-placeholder`} // Custom width, height, text color, background color, and border radius
                                onClick={handleSubmitLogIn}
                                disabled={isClicked}
                            />
                        </div>
                        <div className="p-6"> </div>
                    </div>
                }

            </div>

            {!isSent && <Footer/>}
    {isSent && <div className = "p-10"></div>}
        </div>


    );
}


// export async function getServerSideProps(context: GetServerSidePropsContext) {
export async function getServerSideProps() {

    // const supabase = createServerClient(context)
    // // console.log('fetching user')

    // const { data, error } = await supabase.auth.getUser()
    // console.log(data, error)
    // if (error || !data) {
    //     console.log('error / !data in home', error, data)
    // }

    return { props: {} }
}